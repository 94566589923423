import React from 'react'
import { Link } from 'gatsby'
import { Box, Badge, css } from 'theme-ui'
import rv from '@components/utils/buildResponsiveVariant'
import getReadableColor from '@components/utils/getReadableColor'

const styles = {
  badge: {
    mb: 3
  }
}

const CardBodyCategory = ({ variant, category, omitCategory }) =>
  !omitCategory && category && category.slug ? (
    <Box css={css(styles.badge)} sx={{ variant: rv(variant, 'category') }}>
      <Badge
        variant='tag'
        as={Link}
        to={category.slug}
        sx={
          category.color && {
            bg: `#151A1E60`,
            borderRadius: 9999,
            color: '#fff',
            // border: '2px solid',
            // borderColor: category.color,
            textTransform: 'uppercase',
            fontWeight: 300,
          }
        }
      >
        <svg height="10" width="10">
          <circle cx="4" cy="6" r="4" fill={category.color} />
        </svg>
        &nbsp;
      {category.name}
      </Badge>
    </Box >
  ) : null

export default CardBodyCategory
